import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from "@mui/icons-material/Create";
import SettingsIcon from '@mui/icons-material/Settings';
import { ButtonBase } from '@mui/material';
import { Button, ListScreen, Message, Input, Select } from '../components';
import { fetchTemplates, cleanTemplates, setUser, changeUserSetting, deleteTemplate, templateUploadLogo } from '../actions';
import { __, formatDate, isEmptyString, request, isValidEmail, toNumber } from '../functions';
import { TEMPLATES_EVENTS, IMAGES } from '../config';
import '../assets/styles/templates.css';

/**
 * Emaily.
 */
class TemplatesScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Emaily');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        settingsLoading: false,
        settings: {},
        lightbox: {
            delete: false,
        },
    }};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('templates');
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        // Je aktivne nastavenie?
        const activeSmtp = !_.isEmpty(user.settings.smtp);

        return [
            this.hasPermission('templates-edit') ? {
                name: __('Nový email'),
                color: 'secondary',
                icon: <AddIcon />,
                onClick: () => this.redirect('/template'),
                disabled: !activeSmtp || toNumber(user.user_eshop_id) === 0,
            } : null,
            !activeSmtp && this.hasPermission('templates-smtp') ? {
                name: __('Aktivovať SMTP'),
                color: 'orange',
                icon: <SettingsIcon />,
                onClick: () => this.showCustomSettings(),
                disabled: toNumber(user.user_eshop_id) === 0,
            } : null,
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'templates';
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne emaily');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie emailu.
     */
    async delete() {
        const { deleteTemplate, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme emailu
        await deleteTemplate(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Event po zmene SMTP.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSmtp(name, value) {
        const { user, changeUserSetting } = this.props;

        changeUserSetting('smtp', { ...user.settings.smtp, [name]: value });
    }

    /**
     * Ulozenie smtp.
     */
    saveSmtp() {
        const { user } = this.props;

        let error = '';

        if (isEmptyString(user.settings.smtp.host)) {
            // Nie je zadany host
            error = __('Nie je zadaný server host');
        } else if (isEmptyString(user.settings.smtp.email)) {
            // Nie je zadany email
            error = __('Nie je zadaný email');
        } else if (isEmptyString(user.settings.smtp.password)) {
            // Nie je zadane heslo
            error = __('Nie je zadané heslo');
        } else if (isEmptyString(user.settings.smtp.name)) {
            // Nie je zadany odosielatel
            error = __('Nie je zadaný názov odosielateľa');
        } else if (!isEmptyString(user.settings.smtp.reply) && !isValidEmail(user.settings.smtp.reply)) {
            // Neplatne reply to
            error = __('Neplatný email pre odpoveď');
        }

        if (this.isDemo()) {
            error = __('Nastavenie nie je možné uložiť na DEMO účte');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ settingsLoading: true });

        request('/templates/smtp', user.settings.smtp, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ settingsLoading: false, settingsCustomOpened: false });

            if (status === 'error') {
                // Neplatne udaje
                this.showSnackbar('error', data.message);
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli uložené'));
        });
    }

    /**
     * Nahratie loga.
     *
     * @param {Object} file
     */
    uploadLogo(file) {
        const { templateUploadLogo } = this.props;

        request('/user-eshops/logo', file, 'FILE').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Nepodarilo sa nahrat logo
                this.showSnackbar('error', __('Nepodarilo sa nahrať logo'));
                return;
            }

            // Nastavime logo
            templateUploadLogo(data.url);
        });
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        let buttons = {
            edit: {
                name: __('Upraviť'),
                icon: <EditIcon />,
                callback: () => this.redirect(`/template/${item.id}`),
                options: {},
            },
            delete: {
                name: __('Zmazať'),
                icon: <DeleteIcon />,
                callback: (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                options: {},
            },
        };

        if (!this.hasPermission('templates-edit')) {
            // Nema pravo
            buttons = _.omit(buttons, ['edit']);
        }

        if (!this.hasPermission('templates-delete')) {
            // Nema pravo
            buttons = _.omit(buttons, ['delete']);
        }

        return (
            <div className="templates-grid__item">
                <div className="templates-grid__item__header">
                    <div className="templates-grid__item__header__title">{item.name}</div>
                    <div className="templates-grid__item__header__buttons">
                        {this.renderGridButtons(item.id, buttons)}
                    </div>
                </div>
                <div className="templates-grid__item__content">
                    <img src={IMAGES['email.png']} alt="email" />
                    <span>{TEMPLATES_EVENTS[item.event].name}</span>
                </div>
                <div className="templates-grid__item__footer">
                    {`${__('Vytvorené')} ${formatDate(item.created, 'dd.mm.yyyy hh:ii')}`}
                </div>
            </div>
        );
    }

    /**
     * Mame vlastne custom settingy.
     *
     * @return {boolean}
     */
    hasCustomSettings() {
        return this.hasPermission('templates-smtp');
    }

    /**
     * Render vlastnych settingov.
     *
     * @return {JSX.Element|null}
     */
    renderCustomSettings() {
        const { items, user } = this.props;
        const { settingsLoading } = this.state;

        const userEshopId = toNumber(user.user_eshop_id);

        return (
            <div className="templates-settings">
                <Message type="warning">{__('Pre správne pripojenie si skontrolujte u poskytovateľa SMTP či máte povolené prihlasovanie zo zahraničia.')}</Message>
                <div className="templates-settings__panels">
                    <div className="templates-settings__panels__panel">
                        <Input
                            label={__('Server host')}
                            value={user.settings.smtp.host}
                            onChange={value => this.onChangeSmtp('host', value)}
                            placeholder="smtp.websupport.sk"
                        />
                        <Input
                            label={__('Email')}
                            value={user.settings.smtp.email}
                            onChange={value => this.onChangeSmtp('email', value)}
                            placeholder="info@eshop.sk"
                        />
                        <Input
                            label={__('Heslo')}
                            value={user.settings.smtp.password}
                            onChange={value => this.onChangeSmtp('password', value)}
                            type="password"
                        />
                        <Select
                            label={__('Zabezpečenie pripojenia')}
                            value={user.settings.smtp.encryption}
                            options={{ 'tls': 'TLS', 'ssl': 'SSL' }}
                            onChange={value => this.onChangeSmtp('encryption', value)}
                        />
                        <Input
                            label={__('Port')}
                            value={user.settings.smtp.port}
                            onChange={value => this.onChangeSmtp('port', value)}
                            placeholder={__('Doplnené podľa zabezpečenia pripojenia')}
                        />
                    </div>
                    <div className="templates-settings__panels__panel">
                        <Input
                            label={__('Názov odosielateľa')}
                            value={user.settings.smtp.name}
                            onChange={value => this.onChangeSmtp('name', value)}
                            maxLength={20}
                            placeholder="Eshop.sk"
                        />
                        <Input
                            label={__('Email na ktorý príde odpoveď (reply to)')}
                            value={user.settings.smtp.reply}
                            onChange={value => this.onChangeSmtp('reply', value)}
                            placeholder="info@eshop.sk"
                        />
                        <div className="templates-settings__panels__panel__upload-text">{__('Logo')}</div>
                        <ButtonBase className="templates-settings__panels__panel__upload">
                            {items.logo === ''
                                ? <div className="templates-settings__panels__panel__upload__text">{__('Kliknite alebo presuňte súbor (jpg, png, max. 5MB)')}</div>
                                : <img src={`${items.logo}?v=${Math.random()}`} alt="logo eshop" />}
                            <input type="file" onChange={event => this.uploadLogo(event.target.files[0])} />
                        </ButtonBase>
                    </div>
                </div>
                <Button
                    onClick={() => this.saveSmtp()}
                    loading={settingsLoading}
                    disabled={userEshopId === 0}
                >{__('Uložiť')}</Button>
            </div>
        );
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento email?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
            </div>
        );
    }
}

const stateToProps = ({ templates, user }) => ({ items: templates, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchTemplates,
    clean: cleanTemplates,
    setUser,
    changeUserSetting,
    deleteTemplate,
    templateUploadLogo,
})(TemplatesScreen));
