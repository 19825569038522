import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, MtoknaOrder, Select} from '../components';
import {
    fetchMtoknaWindows,
    cleanMtoknaWindows,
    setUser,
    changeUserSetting,
    changeMtoknaWindowData,
    deleteMtoknaWindow,
    changeMtoknaWindowState,
    readyPickupMtoknaWindow,
    pickupMtoknaWindow,
    activateMtoknaWindow,
} from '../actions';
import {__, formatDate, formatAmount, getOrderButtons, request, toNumber} from '../functions';
import '../assets/styles/mtokna_windows.css';
import EditIcon from "@mui/icons-material/Edit";
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';

/**
 * Objednavky.
 */
class MtoknaWindowsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Žalúzie, sieťky - objednávky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
            loadingSync: false,
            lightbox: {
                delete: false,
                state: false,
                ready: false,
                pickup: false,
                activate: false,
            },
        }};

    // Memory cache
    memoryCacheStates = null;

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback });

        const { items } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user } = this.props;

        if (user.mtokna_type === 'mtokna_admin') {
            return [];
        }

        return [
            {
                name: __('Nová objednávka - žalúzie'),
                onClick: () => this.redirect(`/manual/mo/${user.user_eshop_id}/new/window-blind`),
            },
            {
                name: __('Nová objednávka - sieťky'),
                onClick: () => this.redirect(`/manual/mo/${user.user_eshop_id}/new/window-net`),
            }
        ];
    }

    /**
     * Zobrazime lightbox na zmenu stavu.
     *
     * @param {number} orderId
     * @param {string} stateId
     * @param {string} viewText
     */
    confirmState(orderId, stateId, viewText) {
        this.showLightbox('state', { orderId, stateId, viewText });
    }

    confirmReady(orderId, callbackLoading, callback) {
        this.showLightbox('ready', { orderId, callbackLoading, callback });
    }

    confirmPickup(orderId, callbackLoading, callback) {
        this.showLightbox('pickup', { orderId, callbackLoading, callback });
    }

    confirmActivate(orderId, callbackLoading, callback) {
        this.showLightbox('activate', { orderId, callbackLoading, callback });
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_windows';
    }

    getColumns() {
        const { user } = this.props;

        return [
            <div>{`${__('Číslo')} / ${__('Suma')} / ${__('Dátum')}`}</div>,
            <div>{`${__('Zákaznik')} / ${__('E-mail')} / ${__('Eshop')}`}</div>,
            <div>{`${__('Tel. číslo')} / ${__('Fakturačná adresa')} / ${__('Dodacia adresa')}`}</div>,
            <div>{`${__('Poznámka')}${user.mtokna_type === 'mtokna_admin' ? ` / ${__('Zúčtované MO')}` : ` / ${__('Zúčtované')}`}`}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons(item, {
            settings: {
                meta_data_pdf_link: '',
            },
            hasPermissionView: true,
            hasPermissionDelete: true,
            //hasPermissionPdfOrder: true,
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                buttons,
                callbackLoading,
                callback,
            });
        }

        if (typeof buttons.delete !== 'undefined') {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        // Nastavime callbacky
        /*if (typeof buttons.pdfOrder !== 'undefined') {
            buttons.pdfOrder.callback = (callbackLoading, callback) => this.pdfOrder(item.id, callbackLoading, callback);
        }*/

        let removeButtons = ['sync', 'pdfLink', 'paid', 'send', 'createInvoice', 'pdf'];

        if (user.mtokna_type === 'mtokna_admin') {
            removeButtons = ['sync', 'pdfLink', 'paid', 'send', 'createInvoice', 'pdf', 'delete'];
        }

        let source = <div className="source" style={{ backgroundColor: '#5c70ff' }}>
            {__('Žalúzie')}
        </div>;

        if (item._matchingData.MtoknaWindows.type === 'nets') {
            source = <div className="source" style={{ backgroundColor: '#459350' }}>
                {__('Sieťky')}
            </div>;
        }

        return [
            <div>
                <div style={{ backgroundColor: item.data.state.color }} />
                <div>
                    <div>{item.data.number}</div>
                    {source}
                </div>
                <div>{formatAmount(item.data.amount, item.data.currency)}</div>
                <div>{formatDate(item.data.date)}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.name, { length: 32 })}</div>
                <div>{_.truncate(item.data.client.email, { length: 32 })}</div>
                <div>{_.truncate(item._matchingData.MtoknaWindows.email, { length: 32 })}</div>
            </div>,
            <div>
                <div>{item.data.client.phone}</div>
                <div>{_.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 })}</div>
                <div>{_.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 })}</div>
            </div>,
            <div>
                <div>{item.data.client.delivery_note !== '' ? _.truncate(item.data.client.delivery_note, { length: 32 }) : '-'}</div>
                <div>{toNumber(item._matchingData.MtoknaWindows.accounted) === 1 ? 'Áno' : 'Nie'}</div>
                <div>-</div>
            </div>,
            <div>
                {!this.isMobile() ? <div>
                    <Select
                        options={items.states}
                        value={item.data.state.id}
                        onChange={value => this.confirmState(item.id, value, '')}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {user.mtokna_type === 'mtokna_seller' ? this.renderTableButton(
                        item.id,
                        __('Upraviť'),
                        <EditIcon />,
                        () => this.redirect(`/manual/mo/${item.user_eshop_id}/${item.id}/${item._matchingData.MtoknaWindows.type === 'nets' ? 'window-net' : 'window-blind'}`),
                        {}
                    ) : null}
                    {this.renderTableButton(
                        item.id,
                        __('Aktivovať'),
                        <NotificationsIcon color={item.data.state.id === 'Prijatá' ? 'secondary' : 'inherit'} />,
                        (callbackLoading, callback) => this.confirmActivate(item.id, callbackLoading, callback),
                        {}
                    )}
                    {this.renderTableButton(
                        item.id,
                        __('Pripravené na prevzatie'),
                        <AlarmOnIcon color={item.data.state.id === 'Pripravená na prevzatie' ? 'secondary' : 'inherit'} />,
                        (callbackLoading, callback) => this.confirmReady(item.id, callbackLoading, callback),
                        {}
                    )}
                    {this.renderTableButton(
                        item.id,
                        __('Tovar prevzatý zákazníkom bez výhrad'),
                        <LockOpenIcon color={item.data.state.id === 'Doručená' ? 'secondary' : 'inherit'} />,
                        (callbackLoading, callback) => this.confirmPickup(item.id, callbackLoading, callback),
                        {}
                    )}
                    {this.renderTableButton(
                        item.id,
                        __('PDF zákazník'),
                        <PdfIcon />,
                        (callbackLoading, callback) => this.downloadPdf(item._matchingData.MtoknaWindows.type, item.id, 'first', callbackLoading, callback),
                        {}
                    )}
                    {this.renderTableButton(
                        item.id,
                        __('PDF dodávateľ'),
                        <PdfIcon />,
                        (callbackLoading, callback) => this.downloadPdf(item._matchingData.MtoknaWindows.type, item.id, 'second', callbackLoading, callback),
                        {}
                    )}
                    {this.renderTableButtons(item.id, buttons, removeButtons, false)}
                </div>
            </div>,
        ];
    }

    async downloadPdf(type, id, version, callbackLoading, callback) {
        callbackLoading();

        await request(`/mtokna/${type === 'blinds' ? 'pdfWindowBlind' : 'pdfWindowNet'}/${id}/${version}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        })

        callback();
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {};
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items, user } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'MtoknaWindows-type':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ'),
                        value: '',
                        options: {
                            blinds: __('Žalúzie'),
                            nets: __('Sieťky'),
                        },
                        multiple: true,
                    }}};

                case 'MtoknaWindows-email':
                    if (user.mtokna_type === 'mtokna_seller') {
                        return result;
                    }

                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Partner email'),
                                value: '',
                                autocomplete: true,
                                options,
                            }}};

                case 'MtoknaWindows-name':
                    if (user.mtokna_type === 'mtokna_seller') {
                        return result;
                    }

                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Partner názov'),
                                value: '',
                                autocomplete: true,
                                options,
                            }}};

                case 'MtoknaWindows-accounted':
                    if (user.mtokna_type === 'mtokna_seller') {
                        return result;
                    }

                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Zúčtované MO'),
                                value: '',
                                options: {
                                    0: __('Nie'),
                                    1: __('Áno'),
                                },
                                strict: false,
                            }}};

                case 'number':
                    return { ...result, ...{ [key]: {
                                type: 'input',
                                name: __('Číslo objednávky'),
                                value: '',
                            }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne objednávky');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zmazanie objednavky.
     */
    async delete() {
        const { deleteMtoknaWindow, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme objednavku
        await deleteMtoknaWindow(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Event po zmene stavu objednavky.
     */
    async changeState() {
        const { lightbox } = this.state;
        const { changeMtoknaWindowState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('state');

        // Zobrazime loading
        this.loadingTableButton(this.getLoadingTableButtonId(lightbox.state.orderId, lightbox.state.viewText));

        // Zmenime stav
        await changeMtoknaWindowState(this, lightbox.state.orderId, lightbox.state.stateId);

        // Zrusime loading table button
        this.clearTableButton();
    }

    /**
     * Zmazanie objednavky.
     */
    async activate() {
        const { activateMtoknaWindow } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.activate.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('activate');

        // Zmazeme objednavku
        await activateMtoknaWindow(this, lightbox.activate.orderId);

        // Zavolame callback
        lightbox.activate.callback();
    }

    /**
     * Zmazanie objednavky.
     */
    async ready() {
        const { readyPickupMtoknaWindow } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.ready.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('ready');

        // Zmazeme objednavku
        await readyPickupMtoknaWindow(this, lightbox.ready.orderId);

        // Zavolame callback
        lightbox.ready.callback();
    }

    /**
     * Zmazanie objednavky.
     */
    async pickup() {
        const { pickupMtoknaWindow } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.pickup.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('pickup');

        // Zmazeme objednavku
        await pickupMtoknaWindow(this, lightbox.pickup.orderId);

        // Zavolame callback
        lightbox.pickup.callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items, user, changeMtoknaWindowData } = this.props;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto objednávku?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'state',
                    __('Naozaj chcete zmeniť stav tejto objednávke?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeState()
                )}
                {this.renderLightbox(
                    'activate',
                    __('Naozaj chcete označiť objednávku ako aktivovanú?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.activate()
                )}
                {this.renderLightbox(
                    'ready',
                    __('Naozaj chcete označiť objednávku ako pripravenú na prevzatie?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.ready()
                )}
                {this.renderLightbox(
                    'pickup',
                    __('Naozaj chcete označiť objednávku ako doručenú?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.pickup()
                )}
                {this.renderDrawer('order', {
                    title: __('Objednávka'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaWindowData}
                        settings={items.settings}
                        manual={true}
                        window={true}
                        manualSeller={true}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_windows, user }) => ({ items: mtokna_windows, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaWindows,
    clean: cleanMtoknaWindows,
    setUser,
    changeUserSetting,
    changeMtoknaWindowData,
    deleteMtoknaWindow,
    changeMtoknaWindowState,
    readyPickupMtoknaWindow,
    pickupMtoknaWindow,
    activateMtoknaWindow,
})(MtoknaWindowsScreen));
