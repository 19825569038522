import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Button, Input, Select} from '../components';
import { setUser } from '../actions';
import {__, isEmptyString, request, toNumber} from '../functions';
import '../assets/styles/mtokna_create_product.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-build-full';
import {Autocomplete, ButtonBase, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import UploadIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {CHANGE_MTOKNA_CATEGORY, DELETE_MTOKNA_CATEGORY} from "../actions/types";

/**
 * Objednavka.
 */
class MtoknaCreateProductScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Produkt');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        loadingUpload: false,
        id: '',
        data: {},
        user_id: 0,
        settings: {
            categories: [],
            name: '',
            sku: '',
            description: '',
            price: '',
            stock: '0',
            weight: '0',
            brand: '',
            images: [],
            main_image: '',
        },
        lightbox: {
            delete: false,
            main: false,
            categories: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { params, setUser } = this.props;

            const id = params.id !== 'new' ? params.id : '';

            request(`/mtokna/viewProduct/${id}`).then(response => {
                const { status, data, sync } = response.data;

                if (status === 'error') {
                    window.location = '/products';
                    return;
                }

                setUser(sync.user);

                let { settings, user_id } = this.state;

                if (sync.user.mtokna_type === 'mtokna_owner') {
                    user_id = sync.user.id;
                }

                if (_.has(data, 'data') && !_.isEmpty(data.data)) {
                    user_id = data.user_id;

                    settings = { ...settings, ...{
                        name: data.data.name,
                        categories: _.join(data.data.categories, '==='),
                        sku: data.data.sku,
                        description: data.data.description,
                        price: data.data.price,
                        stock: data.data.stock,
                        weight: data.data.weight,
                        brand: data.data.brand,
                        images: data.data.images,
                        main_image: _.has(data.data, 'main_image')
                            ? data.data.main_image
                            : (!_.isEmpty(data.data.images) ? data.data.images[0] : ''),
                    } };
                }

                this.setState({
                    id,
                    data,
                    settings,
                    user_id,
                });
            });
        }

        return true;
    }

    onChangeSetting(name, value) {
        const { settings } = this.state;

        let additional = {};
        let additional_state = {};

        switch (name) {
            case 'sku':
                value = _.replace(_.toString(value), /[^a-zA-Z0-9_\-]/g, '');
                break;

            case 'stock':
            case 'weight':
            case 'price':
                value = _.replace(_.toString(value), /[^0-9.,]/g, '');
                break;
        }

        this.setState({ settings: { ...settings, [name]: value, ...additional }, ...additional_state });
    }

    onChangeUserId(user_id) {
        this.setState({ user_id });
    }

    save() {
        const { params, user } = this.props;
        let { settings, user_id } = this.state;

        const id = params.id !== 'new' ? params.id : '';

        this.setState({ loading: true });

        settings = { ...settings, categories: _.split(settings.categories, '===') };

        if (id !== '') {
            request(`/mtokna/editProduct/${id}`, { data: settings }, 'POST').then(response => {
                const { status } = response.data;

                this.setState({ loading: false });

                if (status === 'error') {
                    this.showSnackbar('error', __('Nepodarilo sa uložiť produkt'));
                    return;
                }

                this.showSnackbar('success', __('Produkt bol uložený'));

                setTimeout(() => { window.location = '/products' }, 500);
            });

            return;
        }

        if (user.mtokna_type !== 'mtokna_admin') {
            user_id = 0;
        }

        request('/mtokna/createProduct', { user_id, data: settings }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa uložiť produkt'));
                return;
            }

            this.showSnackbar('success', __('Produkt bol uložený'));

            setTimeout(() => { window.location = '/products' }, 500);
        });
    }

    uploadAttachment(file) {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            // Nie je to pdf
            this.showSnackbar('error', __('Neplatný typ súboru'));
            return;
        }

        if (file.size > 5242880) {
            // Velky subor
            this.showSnackbar('error', __('Súbor je väčší ako 5MB'));
            return;
        }

        this.setState({ loadingUpload: true });

        request('/mtokna/uploadProductPhoto', file, 'FILE').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingUpload: false });

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať obrázok'));
                return;
            }

            // Nastavime prilohu
            this.addImage(`https://api.goodeshop.sk/${data.path}`);
        });
    }

    addImage(url) {
        const { settings } = this.state;

        this.onChangeSetting('images', [ ...settings.images, url ]);
    }

    deleteImage() {
        const { settings, lightbox } = this.state;

        const key = lightbox.delete.id;

        let { images } = settings;

        images = _.remove(images, (item, k) => {
            return k !== key;
        });

        this.setState({ settings: { ...settings, images }});

        this.closeLightbox('delete');
    }

    mainImage() {
        const { settings, lightbox } = this.state;

        this.setState({ settings: { ...settings, main_image: lightbox.main.id }});

        this.closeLightbox('main');
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     */
    confirmDelete(id) {
        this.showLightbox('delete', { id });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     */
    confirmMain(id) {
        this.showLightbox('main', { id });
    }

    getCategories(format = true) {
        const { data, user_id } = this.state;

        if (!_.has(data.categories, toNumber(user_id))) {
            return {};
        }

        const raw = data.categories[toNumber(user_id)];

        if (!format) {
            return raw;
        }

        return _.reduce(raw, (result, item) => {
            return { ...result, [item.id]: item.name };
        }, {});
    }

    createCategory() {
        const { lightbox, data, user_id } = this.state;

        if (isEmptyString(lightbox.categories.create_name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        }

        this.onChangeCategory('loading', true);

        const category = {
            name: lightbox.categories.create_name,
            parent_id: lightbox.categories.create_parent_id !== 'parent' ? lightbox.categories.create_parent_id : null,
        };

        request('/mtokna/createCategory', { data: category }, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCategory('loading', false);
                this.showSnackbar('error', __('Kategóriu sa nepodarilo vytvoriť'));
                return;
            }

            const id = response.data.data.category.id;
            const category_data = response.data.data.category;

            this.showSnackbar('success', __('Kategória bola vytvorená'));

            this.setState({ data: { ...data, categories: { ...data.categories, [user_id]: { ...data.categories[user_id], [id]: category_data } } } });

            setTimeout(() => this.resetCategory(), 500);
        });
    }

    editCategory() {
        const { lightbox, data, user_id } = this.state;

        if (isEmptyString(lightbox.categories.edit_name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        }

        this.onChangeCategory('loading', true);

        const id = lightbox.categories.edit_id;
        const category = {
            name: lightbox.categories.edit_name,
            parent_id: lightbox.categories.edit_parent_id !== 'parent' ? lightbox.categories.edit_parent_id : null,
        };

        request(`/mtokna/editCategory/${id}`, { data: category }, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCategory('loading', false);
                this.showSnackbar('error', __('Nepodarilo sa zmeniť kategóriu'));
                return;
            }

            this.showSnackbar('success', __('Kategória bola zmenená'));

            this.setState({ data: { ...data, categories: { ...data.categories, [user_id]: { ...data.categories[user_id], [id]: { ...data.categories[user_id][id], ...category } } } } });

            setTimeout(() => this.resetCategory(), 500);
        })
    }

    deleteCategory(id) {
        const { data, user_id } = this.state;

        if (!window.confirm(__('Naozaj chcete zmazať túto kategóriu'))) {
            return null;
        }

        this.onChangeCategory('loading', true);

        request(`/mtokna/removeCategory/${id}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Objednavku sa nepodarilo zmazat
                this.onChangeCategory('loading', false);
                this.showSnackbar('error', __('Kategóriu sa nepodarilo zmazať'));
                return;
            }

            this.showSnackbar('success', __('Kategória bola zmazaná'));

            const categories = _.omit(data.categories[user_id], [id]);

            this.setState({ data: { ...data, categories: { ...data.categories, [user_id]: categories } } });

            setTimeout(() => this.resetCategory(), 500);
        })
    }

    onChangeCategory(name, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, categories: { ...lightbox.categories, [name]: value } } });
    }

    fillEditCategory(data) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, categories: { ...lightbox.categories, ...{
            edit_name: data.name,
            edit_parent_id: toNumber(data.parent_id) > 0 ? data.parent_id : 'parent',
            edit_id: data.id,
        } } } });
    }

    resetCategory() {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, categories: {
            loading: false,
            edit_name: '',
            edit_parent_id: '',
            edit_id: '',
            create_name: '',
            create_parent_id: 'parent',
        } } });
    }

    getParentCategories(type) {
        const { lightbox, data, user_id } = this.state;

        let user_categories = {};

        if (_.has(data.categories, user_id)) {
            let childs = [];

            if (type === 'edit') {
                childs = this.getChilds(data.categories[user_id], lightbox.categories.edit_id);
            }

            user_categories = _.reduce(data.categories[user_id], (result, item) => {
                const id = toNumber(item.id);

                if (type === 'edit' && id === toNumber(lightbox.categories.edit_id)) {
                    // Not allow same category to parent
                    return result;
                }

                if (type === 'edit' && _.includes(childs, id)) {
                    // Not allow child category to parent
                    return result;
                }

                return { ...result, [item.id]: item.name };
            }, {});
        }

        return { parent: data.category_name, ...user_categories };
    }

    getChilds(categories, category_id) {
        let childs = [];

        for (let i = 0; i < 10; i++) {
            _.each(categories, category => {
                const id = toNumber(category.id);

                if (
                    i === 0
                    && toNumber(category.parent_id) === toNumber(category_id)
                    && !_.includes(childs, id)
                ) {
                    childs = [ ...childs, id ];
                } else if (
                    _.includes(childs, toNumber(category.parent_id))
                    && !_.includes(childs, id)
                ) {
                    childs = [ ...childs, id ];
                }
            });
        }

        return childs;
    }

    renderCategory(data, parent_id, margin) {
        if (toNumber(data.parent_id) !== toNumber(parent_id)) {
            return null;
        }

        return (
            <div className="mtokna_create_product__categories__category" style={{ marginLeft: `${margin}px` }}>
                <div className="mtokna_create_product__categories__category__name">{data.name}</div>
                <div className="mtokna_create_product__categories__category__buttons">
                    <Tooltip title={__('Upraviť')}>
                        <IconButton
                            onClick={() => this.fillEditCategory(data)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={__('Zmazať')}>
                        <IconButton
                            onClick={() => this.deleteCategory(data.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { loading, data, id, settings, loadingUpload, user_id, lightbox } = this.state;
        const { user } = this.props;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        const isEdit = id !== '';

        let allowEdit = true;

        if (user.mtokna_moderator) {
            allowEdit = false;
        }

        if (user.mtokna_type === 'mtokna_admin' && toNumber(user_id) === 0) {
            allowEdit = false;
        }

        const categoriesSelect = <Select
            label={__('Kategórie')}
            value={settings.categories}
            onChange={value => this.onChangeSetting('categories', value)}
            options={this.getCategories()}
            multiple={true}
        />;

        const rawCategories = this.getCategories(false);

        return (
            <div className="mtokna_create_product">
                <div className="mtokna_create_product__header">
                    <div className="mtokna_create_product__header__left">
                        <div className="mtokna_create_product__header__left__title">
                            {isEdit ? settings.name : __('Nový produkt')}
                        </div>
                    </div>
                </div>
                <div className="mtokna_create_product__content">
                    <div className="mtokna_create_product__content__sections">
                        <div className="mtokna_create_product__content__sections__section">
                            {user.mtokna_type !== 'mtokna_owner' ? <Select
                                label={__('Predajca VO')}
                                value={user_id}
                                options={data.owners}
                                onChange={value => this.onChangeUserId(value)}
                                disabled={isEdit}
                            /> : null}
                            {user.mtokna_type === 'mtokna_owner' ? <div className="mtokna_create_product__content__sections__section__categories">
                                {categoriesSelect}
                                <Tooltip title={__('Nastavenia')}>
                                    <IconButton
                                        onClick={() => this.showLightbox('categories', {
                                            loading: false,
                                            edit_name: '',
                                            edit_parent_id: '',
                                            edit_id: '',
                                            create_name: '',
                                            create_parent_id: 'parent',
                                        })}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> : categoriesSelect}
                            <Input
                                label={__('Názov')}
                                value={settings.name}
                                onChange={value => this.onChangeSetting('name', value)}
                                required
                            />
                            <Input
                                label={__('SKU (povolené A-Z 0-9 _-)')}
                                value={settings.sku}
                                onChange={value => this.onChangeSetting('sku', value)}
                                required
                            />
                            <Input
                                label={__('Cena s DPH')}
                                value={settings.price}
                                onChange={value => this.onChangeSetting('price', value)}
                                required
                            />
                            <Input
                                label={__('Stav na sklade')}
                                value={settings.stock}
                                onChange={value => this.onChangeSetting('stock', value)}
                            />
                            <Input
                                label={__('Váha (kg)')}
                                value={settings.weight}
                                onChange={value => this.onChangeSetting('weight', value)}
                            />
                            <Input
                                label={__('Dodávateľ')}
                                content={<Autocomplete
                                    options={data.brands}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => this.onChangeSetting('brand', value !== null ? value.id : '')}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať názov...')}
                                        variant="outlined"
                                    />}
                                    noOptionsText={__('Nenašla sa zhoda')}
                                    clearText={__('Zrušiť')}
                                    value={settings.brand !== ''
                                        ? { id: settings.brand, name: settings.brand}
                                        : null}
                                />}
                            />
                        </div>
                        <div className="mtokna_create_product__content__sections__section">
                            <div className="input">
                                <Typography variant="body2" className="input__label">{__('Obrázky')}</Typography>
                                <div className="mtokna_create_product__content__sections__section__images">
                                    {_.map(settings.images, (image, key) => {
                                        return (
                                            <div key={key}>
                                                <img src={image} />
                                                <Tooltip title={__('Zmazať')}>
                                                    <IconButton onClick={() => this.confirmDelete(key)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={__('Označiť obrázok ako hlavný')}>
                                                    <IconButton onClick={() => this.confirmMain(image)}>
                                                        <CheckCircleIcon color={settings.main_image === image ? 'secondary' : 'inherit'} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="mtokna_create_product__content__sections__section__upload">
                                    {loadingUpload ? this.renderLoading(20) : null}
                                    {!loadingUpload ? <UploadIcon /> : null}
                                    {!loadingUpload ? <div className="mtokna_create_product__content__sections__section__upload__text">{__('Nahrajte obrázok vo formáte jpg, png. Maximálna veľkosť do 5MB.')}</div> : null}
                                    {!loadingUpload ? <input type="file" onChange={event => this.uploadAttachment(event.target.files[0])} /> : null}
                                </div>
                            </div>
                            <div className="input">
                                <Typography variant="body2" className="input__label">{__('Popis')}</Typography>
                                <CKEditor
                                    editor={Editor}
                                    data={settings.description}
                                    onChange={(event, editor) => this.onChangeSetting('description', editor.getData())}
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_create_product__content__button"
                        color="green"
                        disabled={!allowEdit}
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento obrázok?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.deleteImage()
                )}
                {this.renderLightbox(
                    'main',
                    __('Naozaj chcete označiť tento obrázok ako hlavný?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.mainImage()
                )}
                {this.renderLightbox(
                    'categories',
                    __('Nastavenia kategórii'),
                    !_.isEmpty(lightbox.categories) ? (lightbox.categories.loading ? this.renderLoading(20) : <div className="mtokna_create_product__categories">
                        <div className="mtokna_create_product__categories__category">
                            <div className="mtokna_create_product__categories__category__name">{data.category_name}</div>
                        </div>
                        {_.map(rawCategories, (level1) => {
                            const result = this.renderCategory(level1, 0, 30);

                            return (
                                <>
                                    {result}
                                    {result !== null ? _.map(rawCategories, (level2) => {
                                        const result = this.renderCategory(level2, level1.id, 60);

                                        return (
                                            <>
                                                {result}
                                                {result !== null ? _.map(rawCategories, (level3) => {
                                                    const result = this.renderCategory(level3, level2.id, 90);

                                                    return (
                                                        <>
                                                            {result}
                                                            {result !== null ? _.map(rawCategories, (level4) => {
                                                                const result = this.renderCategory(level4, level3.id, 120);

                                                                return (
                                                                    <>
                                                                        {result}
                                                                        {result !== null ? _.map(rawCategories, (level5) => {
                                                                            const result = this.renderCategory(level5, level4.id, 150);

                                                                            return (
                                                                                <>
                                                                                    {result}
                                                                                    {result !== null ? _.map(rawCategories, (level6) => {
                                                                                        const result = this.renderCategory(level6, level5.id, 180);

                                                                                        return (
                                                                                            <>
                                                                                                {result}
                                                                                            </>
                                                                                        );
                                                                                    }) : null}
                                                                                </>
                                                                            );
                                                                        }) : null}
                                                                    </>
                                                                );
                                                            }) : null}
                                                        </>
                                                    );
                                                }) : null}
                                            </>
                                        );
                                    }) : null}
                                </>
                            );
                        })}
                        <div className="mtokna_create_product__categories__panels">
                            <div className="mtokna_create_product__categories__panels__panel">
                                <div className="mtokna_create_product__categories__panels__panel__title">
                                    {__('Nová kategória')}
                                </div>
                                <Input
                                    label={__('Názov')}
                                    value={lightbox.categories.create_name}
                                    onChange={value => this.onChangeCategory('create_name', value)}
                                />
                                <Select
                                    label={__('Nadradená')}
                                    value={lightbox.categories.create_parent_id}
                                    onChange={value => this.onChangeCategory('create_parent_id', value)}
                                    options={this.getParentCategories('create')}
                                    allowEmpty={false}
                                />
                                <Button
                                    onClick={() => this.createCategory()}
                                    color="green"
                                >{__('Vytvoriť')}</Button>
                            </div>
                            <div className="mtokna_create_product__categories__panels__panel">
                                <div className="mtokna_create_product__categories__panels__panel__title">
                                    {__('Úprava kategórie')}
                                </div>
                                <Input
                                    label={__('Názov')}
                                    value={lightbox.categories.edit_name}
                                    onChange={value => this.onChangeCategory('edit_name', value)}
                                    disabled={toNumber(lightbox.categories.edit_id) === 0}
                                />
                                <Select
                                    label={__('Nadradená')}
                                    value={lightbox.categories.edit_parent_id}
                                    onChange={value => this.onChangeCategory('edit_parent_id', value)}
                                    options={this.getParentCategories('edit')}
                                    allowEmpty={false}
                                    disabled={toNumber(lightbox.categories.edit_id) === 0}
                                />
                                <Button
                                    onClick={() => this.editCategory()}
                                    color="primary"
                                    disabled={toNumber(lightbox.categories.edit_id) === 0}
                                >{__('Upraviť')}</Button>
                            </div>
                        </div>
                    </div>) : null,
                    '',
                    __('Zrušiť'),
                    () => {},
                    false,
                    null,
                    'mtokna_create_product__categories-lightbox'
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, {
    setUser,
})(MtoknaCreateProductScreen));
