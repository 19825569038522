import React, { Component } from 'react';
import _ from 'lodash';
import { Snackbar, CircularProgress } from '@mui/material';
import { Drawer } from './Drawer';
import { Message } from './Message';
import { Lightbox } from './Lightbox';
import {toNumber, isMobile, __, getCustomizationKey} from '../functions';
import {
    COOKIE_OFFER_NAME,
    COOKIE_SETTINGS,
    COOKIE_WEBAREAL_NAME,
    DEMO_TOKEN,
    PERMISSIONS
} from '../config';
import {Button} from "./Button";

/**
 * Navigate komponenta.
 */
class Navigate extends Component {

    /**
     * Presmerujeme na path.
     *
     * @param {string} path
     */
    redirect(path) {
        this.props.navigate(path);
    }

    /**
     * Vratime aktualnu path.
     *
     * @return {string}
     */
    getPath() {
        return this.props.location.pathname;
    }

    /**
     * Je to mobilna verzia?
     *
     * @return {boolean}
     */
    isMobile() {
        return isMobile();
    }

    /**
     * Je to demo?
     *
     * @return {boolean}
     */
    isDemo() {
        return !_.isUndefined(global.token) && global.token === DEMO_TOKEN;
    }

    /**
     * Zobrazujeme webareal.
     *
     * @return {boolean}
     */
    isWebareal() {
        const { cookies } = this.props;

        if (
            window.location.search.toString().indexOf('webareal_code') !== -1
            || !_.isUndefined(cookies.get(COOKIE_WEBAREAL_NAME, COOKIE_SETTINGS))
        ) {
            if (!_.isUndefined(global.token) && global.token !== '' && !this.isDemo()) {
                // Je prihlaseny a nie je to demo, nezobrazujeme a zmazeme
                cookies.remove(COOKIE_WEBAREAL_NAME, COOKIE_SETTINGS);
                return false;
            }

            return true;
        }

        return false;
    }

    /**
     * Vratime aktualny krok tutorialu.
     *
     * @return {number}
     */
    getTutorialStep() {
        const { user } = this.props;

        if (_.isEmpty(user)) {
            return 0;
        }

        return toNumber(user.user_eshop_id) === 0 ? 1 : toNumber(user.settings.tutorial_step);
    }

    /**
     * Ma pravo?
     *
     * @param {string} name
     *
     * @return {boolean}
     */
    hasPermission(name) {
        // Vytiahneme zoznam prav
        const permissions = this.getPermissions();

        let valid = _.has(permissions, name) && permissions[name];

        if (valid && name.indexOf('-') !== -1) {
            // Ma pravo ale je to podpravomoc, skontrolujeme hlavnu
            const parsed = name.split('-');

            return _.has(permissions, parsed[0]) && permissions[parsed[0]];
        }

        return valid;
    }

    /**
     * Vratime zoznam prav.
     *
     * @return {Object}
     */
    getPermissions() {
        const { user } = this.props;

        // Vytiahneme permissions usera
        const userPermissions = _.has(user, 'permissions') && !_.isEmpty(user.permissions)
            ? user.permissions
            : {};

        return { ...this.getDefaultPermissions(), ...userPermissions };
    }

    /**
     * Vratime zoznam default pravomoci.
     *
     * @return {Object}
     */
    getDefaultPermissions() {
        const { user } = this.props;

        // Vytiahneme zoznam
        let permissions = _.reduce(PERMISSIONS, (result, { value }, name) => ({ ...result, [name]: value }), {});

        if (_.has(user, 'type') && (user.type === 'user' || user.type === 'admin')) {
            // Ak je admin, povolime pohyby
            permissions = { ...permissions, bmails: true };
        }

        return permissions;
    }

    /**
     * Zobrazime snackbar.
     *
     * @param {string} type
     * @param {string} message
     */
    showSnackbar(type, message) {
        this.setState({ snackbar: { ...this.state.snackbar, ...{ type, message } } });
    }

    /**
     * Event na schovanie snackbaru.
     */
    onCloseSnackbar() {
        this.setState({ snackbar: { ...this.state.snackbar, type: null } });
    }

    /**
     * Zobrazime drawer.
     *
     * @param {string} name
     * @param {Object} value
     */
    showDrawer(name, value = {}) {
        if (this.isDrawerOpened(name)) {
            // Je otvoreny zatvarame
            this.closeDrawer(name);
            return;
        }

        this.setState({ drawers: { [name]: value } });
    }

    /**
     * Zavrieme drawer.
     *
     * @param {string} name
     */
    closeDrawer(name) {
        let drawers = _.has(this.state, 'drawers') ? this.state.drawers : {};

        drawers = _.omit(drawers, [name]);

        this.setState({ drawers });
    }

    /**
     * Je otvoreny drawer?
     *
     * @param {string} name
     *
     * @return {boolean}
     */
    isDrawerOpened(name) {
        return _.has(this.state, 'drawers') && _.has(this.state.drawers, name);
    }

    /**
     * Zobrazime lightbox.
     *
     * @param {string} type
     * @param {object,null} data
     */
    showLightbox(type, data = null) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: data !== null ? data : true } });
    }

    /**
     * Zatvorime lightbox.
     *
     * @param {string} type
     */
    closeLightbox(type) {
        this.onCloseLightbox(type);
    }

    /**
     * Event po zatvoreny lightboxu.
     *
     * @param {string} type
     */
    onCloseLightbox(type) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: false } });
    }

    /**
     * Ma fakturacny system dostupnu funkciu?
     *
     * @param {string} type
     * @param {number|null} accountingId
     * @param {boolean|null} defaultReturn
     *
     * @return {boolean}
     */
    hasAccountingAction(type, accountingId = null, defaultReturn = true) {
        if (_.has(this.props, 'user')) {
            const { user } = this.props;

            if (accountingId === null) {
                accountingId = user.accounting_id;
            }

            accountingId = toNumber(accountingId);

            if (accountingId === 0) {
                // Nemame fakturacny system
                return false;
            }

            // Zformatujeme zoznam fakturacnych systemom
            const accountings = _.reduce(user.accountings, (result, accounting) => {
                return { ...result, [toNumber(accounting.id)]: accounting.settings };
            }, {});

            if (_.has(accountings, accountingId)) {
                // Mame fakturacny system
                const settings = accountings[accountingId];

                if (_.has(settings, type)) {
                    // Vratime nastavenie
                    return settings[type];
                }
            }
        }

        return defaultReturn;
    }

    /**
     * Rendrovanie snackbaru.
     *
     * @returns {JSX.Element}
     */
    renderSnackbar() {
        if (!_.has(this.state, 'snackbar')) {
            return null;
        }

        const { type, message } = this.state.snackbar;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!_.isEmpty(type)}
                autoHideDuration={6000}
                onClose={this.onCloseSnackbar.bind(this)}
                style={{ zIndex: 50000000 }}
                message={<Message type={type}>{message}</Message>}
            />
        );
    }

    /**
     * Rendrovanie loadingu.
     *
     * @param {number} size
     * @param {string} color
     *
     * @return {JSX.Element}
     */
    renderLoading(size = 40, color = 'primary') {
        return <CircularProgress
            className={`loading ${_.has(this.props, 'user') && _.isEmpty(this.props.user) && !this.isMobile() ? 'loading-window' : ''}`}
            color={color}
            size={size}
        />;
    }

    /**
     * Rendrujeme drawer.
     *
     * @param {string} name
     * @param {Object} props
     *
     * @return {JSX.Element|null}
     */
    renderDrawer(name, props = {}) {
        if (this.isDrawerOpened(name)) {
            return <Drawer
                name={name}
                onClose={() => this.closeDrawer(name)}
                contentProps={this.state.drawers[name]}
                {...props}
            />;
        }

        return null;
    }

    /**
     * Rendrujeme lightbox.
     *
     * @param {string} type
     * @param {string} title
     * @param {JSX.Element} content
     * @param {string} agreeText
     * @param {string} closeText
     * @param {function|null} onAgree
     * @param {boolean} disableClose
     * @param {function|null} onClose
     * @param {string} className
     *
     * @return {JSX.Element}
     */
    renderLightbox(
        type,
        title,
        content,
        agreeText = '',
        closeText = '',
        onAgree = null,
        disableClose = false,
        onClose = null,
        className = ''
    ) {
        return <Lightbox
            title={title}
            content={content}
            open={_.isBoolean(this.state.lightbox[type]) ? this.state.lightbox[type] : true}
            agreeText={agreeText}
            closeText={closeText}
            onAgree={onAgree}
            onClose={onClose !== null ? onClose : () => this.closeLightbox(type)}
            disableClose={disableClose}
            className={className}
        />;
    }

    /**
     * Akceptacia offer.
     */
    acceptOffer() {
        const { cookies } = this.props;

        this.setState({ readOffer: true });

        // Ulozime token do cookie
        cookies.set(COOKIE_OFFER_NAME, 'yes', COOKIE_SETTINGS);
    }

    /**
     * Ma precitane offer?
     *
     * @return {boolean}
     */
    readOffer() {
        // Pozrieme sa ci mame ulozeny token
        const { cookies } = this.props;

        return !_.isUndefined(cookies.get(COOKIE_OFFER_NAME, COOKIE_SETTINGS));
    }

    renderOffer() {
        if (!_.has(this.state, 'readOffer') || this.state.readOffer) {
            return null;
        }

        return (
            <div className="offer">
                <div className="offer__content">
                    <div className="offer__content__text"><span>{__('Získajte 1 rok členstva zadarmo!')}</span>{__('Stačí keď si do 22.12 predĺžite členstvo o rok a ďalší rok získate úplne zdarma.')}</div>
                    <Button
                        onClick={() => this.acceptOffer()}
                    >{__('Nezobrazovať')}</Button>
                </div>
            </div>
        );
    }

    isMtokna() {
        return getCustomizationKey() === 'mtokna';
    }
}

export { Navigate };
