import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Screen, Logo, Input, Button } from '../components';
import { __, request, getCustomization } from '../functions';
import { IMAGES, COOKIE_TOKEN_NAME, COOKIE_SETTINGS } from '../config';
import '../assets/styles/login.css';

/**
 * Prihlasenie.
 */
class LoginScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Prihlásenie');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        email: '',
        password: '',
        loading: false,
        socialLinks: null,
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame linky na soc siete
            request('/users/social-links').then(response => {
                const { data } = response.data;

                this.setState({ socialLinks: data });
            });
        }

        return true;
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    /**
     * Prihlasenie.
     *
     * @return boolean
     */
    login() {
        const { email, password } = this.state;

        if (_.isEmpty(email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Nevyplnili ste email'));
            return false;
        } else if (_.isEmpty(password)) {
            // Nie je vyplnene heslo
            this.showSnackbar('error', __('Nevyplnili ste heslo'));
            return false;
        }

        this.setState({ loading: true });

        request(this.isMtokna() ? '/mtokna/login' : '/users/login', { email, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Nesprávne prihlasovacie údaje'));
                return;
            }

            if (this.isMtokna() && _.has(data, 'mtokna_type') && data.mtokna_type === 'mtokna_admin') {
                // Zavolame callback
                this.afterLogin(data.token, '/orders');
                return;
            }

            // Zavolame callback
            this.afterLogin(data.token);
        });
    }

    /**
     * Callback po prihlaseni.
     *
     * @param {string} token
     * @param {string} redirect
     */
    afterLogin(token, redirect = '/dashboard') {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set(COOKIE_TOKEN_NAME, token, COOKIE_SETTINGS);

        window.location = redirect;
    }

    /**
     * Prihlasenie cez soc. siete.
     *
     * @param {string} link
     */
    socialLogin(link) {
        window.location = link;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { email, password, loading, socialLinks } = this.state;

        if (socialLinks === null) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        // Mame customizaciu?
        const hasCustomization = getCustomization() !== null;

        return (
            <div className="login">
                <Logo onClick={() => { window.location = '/'; }} color="dark" />
                <div className="login__title">{__('Prihlásenie')}</div>
                <div className="login__text">{__('Pre pokračovanie sa prosím prihláste')}</div>
                {!hasCustomization ? <div className="login__social">
                    <ButtonBase
                        onClick={!_.isEmpty(socialLinks)
                            ? () => this.socialLogin(socialLinks.google_login)
                            : () => {}}
                        className="login__social__button google"
                        variant="outlined"
                        color="inherit"
                    >
                        <img src={IMAGES['google.svg']} alt="google logo" />
                        <span>{__('Prihlásiť cez Google')}</span>
                    </ButtonBase>
                </div> : null}
                {!hasCustomization ? <div className="login__or">{__('Alebo sa prihláste cez email a heslo')}</div> : null}
                <Input
                    type="email"
                    label={__('Email')}
                    value={email}
                    onChange={value => this.onChangeValue('email', value)}
                />
                <Input
                    type="password"
                    label={__('Heslo')}
                    value={password}
                    onChange={value => this.onChangeValue('password', value)}
                />
                <Button
                    onClick={() => this.login()}
                    className="login__button"
                    loading={loading}
                >{__('Prihlásiť')}</Button>
                {!hasCustomization ? <ButtonBase
                    onClick={() => this.redirect('/register')}
                    className="login__button-switch"
                >{__('Ešte nemáte účet? Registrujte sa.')}</ButtonBase> : null}
                {this.isMtokna() ? <ButtonBase
                    onClick={() => {}}
                    className="login__button-switch"
                >{__('Ešte nemáte prihlasovacie údaje? Kontaktujte nás na info@dobrezeleziarstvo.sk')}</ButtonBase> : null}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(LoginScreen));
