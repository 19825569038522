import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Badge,
    IconButton,
    Tooltip,
    CircularProgress,
    TableFooter,
    TablePagination,
    ButtonBase, Autocomplete, TextField,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';
import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import UnlockIcon from '@mui/icons-material/LockOpen';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import { Screen, Button, Select, Input, Checkbox } from '../components';
import { __, formatDate, toNumber, plural, isEmptyString, request } from '../functions';
import '../assets/styles/list.css';

/**
 * List screen komponenta.
 */
class ListScreen extends Screen {
    /**
     * Default state.
     *
     * @type {Object}
     */
    static state = {
        settingsOpened: false,
        settingsCustomOpened: false,
        filterOpened: false,
        filtered: {},
        multiselectIds: [],
        multiselectAll: false,
        multiselectLoading: null,
        multiselectLocked: false,
        tableButtonLoading: null,
        gridButtonLoading: null,
        cellsSettings: [],
        cellsSettingsLoading: false,
        moreOpened: [],
    };

    page = 1;

    /**
     * Drzi shift?
     *
     * @type {boolean}
     */
    holdShift = false;

    /**
     * Posledny kliknuty checkbox.
     *
     * @type {null|number}
     */
    lastCheckbox = null;

    /**
     * Default filtered.
     *
     * @type {Object}
     */
    defaultFiltered = {};

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        // Nastavime listener
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('keyup', this.keyUp);

        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetchList(this.page, 0, this.defaultFiltered);
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Zmazeme listener
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('keyup', this.keyUp);

        // Vycistime zoznam
        this.cleanList();
    }

    /**
     * Stlacenie klavesi.
     *
     * @param {Object} event
     */
    keyDown = ({ key }) => {
        if (key === 'Shift') {
            this.holdShift = true;
        }
    }

    /**
     * Pustenie klavesi.
     *
     * @param {Object} event
     */
    keyUp = ({ key }) => {
        if (key === 'Shift') {
            this.holdShift = false;
        }
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback });

        const { items } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    afterFetchList() {}

    /**
     * Vycistime zoznam.
     */
    cleanList() {
        const { clean } = this.props;

        // Vycistime zoznam
        clean();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        return [];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        return [];
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        return {};
    }

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return true;
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Nenašli sme žiadne položky');
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return '';
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {};
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    prepareMultiselect() {
        return _.reduce(this.getMultiselect(), (result, action) => {
            if (_.has(action, 'permission') && !this.hasPermission(action.permission)) {
                // Akcia vyzaduje permission a nie je
                return result;
            }

            return [ ...result, action ];
        }, []);
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        const { user } = this.props;

        if ((user.is_seller || user.is_seller_owner) && window.location.pathname === '/sellers-orders') {
            return [
                <div>{`${__('Číslo')} / ${__('Suma')} / ${__('Dátum')}`}</div>,
                <div>{`${__('Zákaznik')} / ${__('E-mail')} / ${__('Tel. číslo')}`}</div>,
                <div>{`${__('Názov predajcu')} / ${__('Adresa predajcu')} / ${__('Poznámka predajca')}`}</div>,
                <div>{`${__('Odovzdané')} / ${__('Dátum odovzdania')} / ${user.is_seller_owner ? __('Poznámka eshop') : '-'}`}</div>,
                <div>{`${__('Výdajka')} / ${__('Zúčtované')} / ${__('Číslo faktúry')}`}</div>,
                <div></div>,
            ];
        }

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        let columns = _.reduce(cellsSettings.settings, (result, column) => {
            const labels = _.reduce(column, (result, name) => ([ ...result, cellsOptions[name].label ]), []);

            return [ ...result, <div>{this.isMobile() ? labels[0] : labels.join(' / ')}</div> ];
        }, []);

        if (_.isEmpty(columns)) {
            // Nemame stlpce
            return [];
        }

        return [ ...columns, this.getLastColumnText() ];
    }

    /**
     * Vratime text posledneho stlpca.
     *
     * @return {string}
     */
    getLastColumnText() {
        return '';
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        const { user } = this.props;

        const tableName = this.getTableName();

        return _.has(user.settings.cells, tableName) ? user.settings.cells[tableName] : {};
    }

    /**
     * Vratime hodnotu bunky.
     *
     * @param {string} value
     *
     * @return {JSX.Element}
     */
    getCellValue(value) {
        return <div>{!isEmptyString(value) ? value : '-'}</div>;
    }

    /**
     * Ma multiselect?
     *
     * @return {boolean}
     */
    hasMultiselect() {
        return !_.isEmpty(this.prepareMultiselect());
    }

    /**
     * Event po zmene checkboxu.
     *
     * @param {number} id
     * @param {boolean} checked
     */
    onChecked(id, checked) {
        let { multiselectIds } = this.state;
        let selected = [id];

        if (this.holdShift && this.lastCheckbox !== null) {
            // Drzi shift a pred tym sme klikli na checkbox
            const { items } = this.props;

            _.each(items.items, item => {
                const item_id = toNumber(item.id);

                if (id < this.lastCheckbox) {
                    // Oznacene id je mensie ako posledne, to znamena ze oznacujeme smerom dole
                    if (item_id < this.lastCheckbox && item_id > id) {
                        selected = [ ...selected, item_id ];
                    }
                } else {
                    // Oznacene id je vacsie ako posledne to znamena ze oznacujeme smerom hore
                    if (item_id > this.lastCheckbox && item_id < id) {
                        selected = [ ...selected, item_id ];
                    }
                }
            });
        }

        if (checked) {
            // Zaskrtnute, pridame
            multiselectIds = [ ...multiselectIds, ...selected ];
        } else {
            // Odskrtnute, odstranime
            if (selected.length > 1) {
                // Odskrtavame aj posledne oznaceny
                selected = [ ...selected, this.lastCheckbox ];
            }

            multiselectIds = _.remove(multiselectIds, i => !_.includes(selected, i));
        }

        // Nastavime referenciu na posledny checkbox
        this.lastCheckbox = id;

        let state = { multiselectIds };

        if (!checked) {
            // Odskrtli sme checkbox, zrusime pripadne vsetky checkboxy
            state = { ...state, multiselectAll: false };
        }

        this.setState(state);
    }

    /**
     * Event po zmene vsetkych checkboxov.
     *
     * @param {boolean} checked
     */
    onCheckedAll(checked) {
        const { multiselectAll } = this.state;
        const { items } = this.props;

        this.setState({
            // Ak zaskrtneme vsetky checkboxy, pridame idcka celej strany, inak zrusime vsetky
            multiselectIds: !multiselectAll ? _.map(items.items, ({ id }) => toNumber(id)) : [],
            multiselectAll: !multiselectAll,
        });
    }

    /**
     * Je zaskrtnuty?
     *
     * @param {number} id
     *
     * @return {boolean}
     */
    isChecked(id) {
        const { multiselectIds } = this.state;

        return _.includes(multiselectIds, id);
    }

    /**
     * Zavolame multiselect akciu.
     *
     * @param {Object} action
     * @param {number} key
     */
    callMultiselectAction(action, key) {
        // Vytiahneme idcka
        const ids = this.getMultiselectIds();

        if (_.has(action, 'confirm')) {
            // Mame nastaveny confirm, zobrazime
            action.confirm(ids, () => this.clearMultiselect(), () => this.loadingMultiselect(key));
            return;
        }

        // Zobrazime loading
        this.loadingMultiselect(key);

        // Zavolame callback
        action.callback(ids, () => this.clearMultiselect());
    }

    /**
     * Vratime idcka pre multiselect.
     *
     * @return {string}
     */
    getMultiselectIds() {
        const { items } = this.props;
        const { multiselectIds, multiselectAll } = this.state;

        // Idcka all nastavime iba ak je nastavene vsetko ale vysledkov je viac ako na aktualnej stranke
        return multiselectAll && items.total > multiselectIds.length ? 'all' : multiselectIds.join(',');
    }

    /**
     * Zamkneme/Odomkneme multiselect
     */
    lockMultiselect() {
        const { multiselectLocked } = this.state;

        if (multiselectLocked) {
            // Je zamknute, zrusime aj oznacene polozky
            this.setState({
                multiselectLocked: false,
                multiselectIds: [],
                multiselectAll: false,
            });
            return;
        }

        this.setState({ multiselectLocked: true });
    }

    /**
     * Zobrazime loading.
     *
     * @param {number} key
     */
    loadingMultiselect(key) {
        this.setState({ multiselectLoading: key });
    }

    /**
     * Vycistime multiselect.
     */
    clearMultiselect() {
        const { multiselectLocked } = this.state;

        if (multiselectLocked) {
            // Zoznam je zamknuty
            this.setState({ multiselectLoading: null });
            return;
        }

        this.setState({
            multiselectLoading: null,
            multiselectIds: [],
            multiselectAll: false,
        });
    }

    /**
     * Zobrazime table button loading.
     *
     * @param {string} buttonId
     */
    loadingTableButton(buttonId) {
        this.setState({ tableButtonLoading: buttonId });
    }

    /**
     * Zobrazime grid button loading.
     *
     * @param {string} buttonId
     */
    loadingGridButton(buttonId) {
        this.setState({ gridButtonLoading: buttonId });
    }

    /**
     * Vycistime table button loading.
     */
    clearTableButton() {
        this.setState({ tableButtonLoading: null });
    }

    /**
     * Vycistime grid button loading.
     */
    clearGridButton() {
        this.setState({ gridButtonLoading: null });
    }

    /**
     * Vratime unikatne button id pre table button.
     *
     * @param {number} orderId
     * @param {string} text
     *
     * @return {string}
     */
    getLoadingTableButtonId(orderId, text) {
        return `${orderId}${text}`;
    }

    /**
     * Vratime unikatne button id pre grid button.
     *
     * @param {number} orderId
     * @param {string} text
     *
     * @return {string}
     */
    getLoadingGridButtonId(orderId, text) {
        return `${orderId}${text}`;
    }

    /**
     * Zobrazime/schovame filter.
     */
    showFilter() {
        this.setState({ filterOpened: !this.state.filterOpened, settingsOpened: false });
    }

    /**
     * Zobrazime/schovame nastavenia.
     */
    showSettings() {
        this.setState({ settingsOpened: !this.state.settingsOpened, filterOpened: false });
    }

    /**
     * Zobrazime/schovame custom nastavenia.
     */
    showCustomSettings() {
        this.setState({ settingsCustomOpened: !this.state.settingsCustomOpened, filterOpened: false });
    }

    /**
     * Event po zmene filtru.
     *
     * @param {string} key
     * @param {string|number} value
     */
    onChangeFilter(key, value) {
        const { filtered } = this.state;

        this.setState({ filtered: { ...filtered, [key]: value } });
    }

    /**
     * Ulozime filter.
     *
     * @param {Object} filtered
     */
    saveFilter(filtered) {
        // Vyresetujeme filter
        this.setState({ filtered: {}, filterOpened: false });

        // Vycistime zoznam
        this.cleanList();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetchList(1, 0, filtered);
    }

    /**
     * Resetujeme filter.
     */
    resetFilter() {
        // Ulozime s prazdnym filtrom
        this.saveFilter({});
    }

    /**
     * Zmazeme filtered field.
     *
     * @param {string} key
     */
    deleteFiltered(key) {
        let { filtered } = this.state;

        filtered = _.omit(filtered, [key]);

        this.saveFilter(filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        this.defaultState = {};

        // Vycistime zoznam
        this.cleanList();

        const { items } = this.props;

        // Nacitame pozadovanu stranku
        this.fetchList(page, 0, items.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.cleanList();

        const { items } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetchList(1, per_page, items.filtered);
    }

    /**
     * Pripravime bunky pre tabulku.
     *
     * @param {Array} cells
     *
     * @return {Array}
     */
    prepareCells(cells) {
        if (!this.isMobile() || _.isEmpty(cells)) {
            // Nie je mobilna verzia
            return cells;
        }

        // Na mobile chceme prvu a poslednu bunku
        return [
            cells[0],
            cells[cells.length - 1]
        ];
    }

    /**
     * Event po zmene cell settingu.
     *
     * @param {number} key
     * @param {number} subKey
     * @param {string} value
     */
    onChangeCellSetting(key, subKey, value) {
        let { cellsSettings } = this.state;

        if (_.isEmpty(cellsSettings)) {
            // Zatial nie su nasetovane, nastavime
            cellsSettings = this.getCellsSettings();
        }

        this.setState({ cellsSettings: { ...cellsSettings, settings: {
            ...cellsSettings.settings, [key]: { ...cellsSettings.settings[key], [subKey]: value },
        } } });
    }

    /**
     * Ulozime nastavenia buniek.
     */
    saveCellsSettings() {
        const { user, changeUserSetting } = this.props;
        const { cellsSettings } = this.state;

        this.setState({ cellsSettingsLoading: true });

        // Vytiahneme nazov tabulky
        const tableName = this.getTableName();

        // Upravime nastavenia
        const cells = { ...user.settings.cells, [tableName]: {
            ...user.settings.cells[tableName],
            settings: cellsSettings.settings,
        } };

        // Ulozime nastavenie
        request('/user-eshops/changeSetting', { cells: JSON.stringify(cells) }).then(response => {
            this.setState({
                cellsSettingsLoading: false,
                settingsOpened: false,
                cellsSettings: [],
            });

            // Zmenime nastavenia
            changeUserSetting('cells', cells);
        });
    }

    /**
     * Zobrazime viac buttonov.
     *
     * @param {number} id
     */
    showMoreButtons(id) {
        const { moreOpened } = this.state;

        this.setState({ moreOpened: [ ...moreOpened, id ] });
    }

    /**
     * Has settings.
     *
     * @return {boolean}
     */
    hasSettings() {
        return true;
    }

    /**
     * Schovame multiselect.
     *
     * @return {boolean}
     */
    showMultiselect() {
        return true;
    }

    /**
     * Mame vlastne custom settingy.
     *
     * @return {boolean}
     */
    hasCustomSettings() {
        return false;
    }

    /**
     * Render vlastnych settingov.
     *
     * @return {JSX.Element|null}
     */
    renderCustomSettings() {
        return null;
    }

    /**
     * Render after header.
     *
     * @return {JSX.Element|null}
     */
    renderAfterHeader() {
        return null;
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return null;
    }

    /**
     * Rendrujeme polozku gridu.
     *
     * @param {Object} item
     *
     * @return {JSX.Element|null}
     */
    renderGridItem(item) {
        return null;
    }

    /**
     * Rendrujeme custom filter.
     *
     * @return {JSX.Element|null}
     */
    renderCustomFilter() {
        return null;
    }

    /**
     * Rendrujeme header.
     *
     * @return {JSX.Element}
     */
    renderHeader() {
        const { items } = this.props;
        const {
            settingsOpened,
            settingsCustomOpened,
            filterOpened,
            multiselectIds,
            multiselectAll,
            multiselectLoading,
            multiselectLocked,
        } = this.state;

        // Vytiahneme filter
        const filter = this.getFilter();

        // Vytiahneme zoznam tagov
        const tags = this.getTags();

        let headerLeft = (
            <div className="list__header__left">
                <div className="list__header__left__title">{this.title}</div>
                <div className="list__header__left__tags">
                    {_.map(tags, (tag, key) => {
                        if (tag === null) {
                            return null;
                        }

                        // Klikatelne?
                        const clickable = _.has(tag, 'onClick');

                        return <Chip
                            className={_.has(tag, 'className') ? tag.className : ''}
                            onClick={clickable ? () => tag.onClick() : () => {}}
                            color={_.has(tag, 'color') ? tag.color : (clickable ? 'primary' : 'default')}
                            label={tag.name}
                            icon={_.has(tag, 'icon') ? tag.icon : null}
                            disabled={_.has(tag, 'disabled') ? tag.disabled : false}
                            key={key}
                        />;
                    })}
                </div>
            </div>
        );

        // Custom filter
        const customFilter = this.renderCustomFilter();

        // Filter
        const filterContent = !_.isEmpty(filter) && customFilter === null ? <Tooltip title={__('Filter')}>
            <IconButton
                onClick={() => this.showFilter()}
                className="list__header__right__button"
            >
                <Badge badgeContent={_.keys(items.filtered).length} color="primary">
                    <FilterIcon color={filterOpened ? 'primary' : 'inherit'} />
                </Badge>
            </IconButton>
        </Tooltip> : customFilter;

        let headerRight = (
            <div className="list__header__right">
                {filterContent}
                {!this.isMobile() && !_.isEmpty(this.getCellsSettings()) && this.hasSettings() ? <Tooltip title={__('Nastavenia stĺpcov')}>
                    <IconButton
                        onClick={() => this.showSettings()}
                        className="list__header__right__button"
                    >
                        <SettingsIcon color={settingsOpened ? 'primary' : 'inherit'} />
                    </IconButton>
                </Tooltip> : null}
                {this.hasCustomSettings() ? <Tooltip title={__('Nastavenia')}>
                    <IconButton
                        onClick={() => this.showCustomSettings()}
                        className="list__header__right__button"
                    >
                        <SettingsIcon color={settingsCustomOpened ? 'primary' : 'inherit'} />
                    </IconButton>
                </Tooltip> : null}
            </div>
        );

        // Je aktivny multiselect?
        const activeMultiselect = !_.isEmpty(multiselectIds);

        if (activeMultiselect) {
            // Mame aktivny multiselect
            const total = multiselectAll ? items.total : multiselectIds.length;

            headerLeft = (
                <div className="list__header__left">
                    <div className="list__header__left__title">
                        {`${total} ${plural(total, [
                            __('označená položka'),
                            __('označené položky'),
                            __('označených položiek'),
                        ])}`}
                    </div>
                </div>
            );

            // Vytiahneme multiselect
            let multiselect = this.prepareMultiselect();

            if (multiselect.length > 1) {
                // Mame viac ako jednu multiselect akciu, pridame lock button
                multiselect = [ {
                    onClick: () => this.lockMultiselect(),
                    icon: !multiselectLocked ? <LockIcon /> : <UnlockIcon />,
                    text: !multiselectLocked
                        ? __('Uzamknutie označených položiek. Položky ostanú označené po vykonaní akcie.')
                        : __('Odomknutie označených položiek'),
                }, ...multiselect ];
            }

            headerRight = (
                <div className="list__header__right">
                    {_.map(multiselect, (action, key) => {
                        if (action === null) {
                            return null;
                        }

                        let { icon, text, limit } = action;

                        if (_.has(action, 'validation') && !multiselectAll) {
                            // Mame validacny callback a neoznacujeme vsetko
                            let valid = true;

                            _.each(items.items, item => {
                                if (valid && this.isChecked(toNumber(item.id))) {
                                    // Je zaskrtnuty validujeme
                                    valid = action.validation(item);
                                }
                            });

                            if (!valid) {
                                // Akcia nie je validna
                                return null;
                            }
                        }

                        limit = toNumber(limit);

                        // Je prekroceny limit akcie?
                        const reachedLimit = limit > 0 && (multiselectIds.length > limit || (multiselectAll && total > limit));

                        if (multiselectLoading === key) {
                            // Zobrazime loading
                            icon = <CircularProgress color="inherit" size={20} />;
                        }

                        if (limit > 0) {
                            // Mame limit zobrazime ho v nazve
                            text = `${text} (max ${limit} ${__('položiek')})`;
                        }

                        return (
                            <Tooltip title={text} key={key}>
                                <span>
                                    <IconButton
                                        onClick={_.has(action, 'onClick')
                                            ? () => action.onClick()
                                            : () => this.callMultiselectAction(action, key)}
                                        disabled={reachedLimit}
                                        className="list__header__right__button"
                                    >{icon}</IconButton>
                                </span>
                            </Tooltip>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className={`list__header ${activeMultiselect ? 'multiselect' : ''}`}>
                {headerLeft}
                {headerRight}
                {this.renderFiltered()}
                {this.renderFilter(filter)}
                {this.renderSettings()}
                {settingsCustomOpened
                    ? <div className="list__header__custom-settings">{this.renderCustomSettings()}</div>
                    : null}
            </div>
        );
    }

    /**
     * Rendrovanie filtered.
     *
     * @return {JSX.Element|null}
     */
    renderFiltered() {
        const { filterOpened, filtered } = this.state;

        if (filterOpened || _.isEmpty(filtered)) {
            // Nezobrazujeme ak nie je filtrovanie alebo je otvoreny filter alebo je multiselect
            return null;
        }

        // Vytiahneme filter
        const filter = this.getFilter();

        return (
            <div className="list__header__filtered">
                {_.map(filtered, (value, key) => {
                    let label = null;

                    key = key.replace(' >=', '-from').replace(' <=', '-to');

                    switch (filter[key].type) {
                        case 'select':
                            if (_.toString(value).indexOf('===') !== -1) {
                                // Multiple
                                label = `${filter[key].name}: ${_.join(_.map(_.split(_.toString(value), '==='), v => filter[key].options[v]), ', ')}`;
                            } else {
                                label = `${filter[key].name}: ${filter[key].options[value]}`;
                            }
                            break;

                        case 'input':
                            label = `${filter[key].name}: "${value}"`;
                            break;

                        case 'date':
                            label = `${filter[key].name}: "${formatDate(value, 'dd.mm.yyyy')}"`;
                            break;

                        default:
                            label = null;
                            break;
                    }

                    return <Chip
                        className="list__header__filtered__value"
                        label={label}
                        onDelete={() => this.deleteFiltered(key)}
                        color="primary"
                    />;
                })}
            </div>
        );
    }

    /**
     * Rendrovanie nastaveni.
     *
     * @return {JSX.Element|null}
     */
    renderSettings() {
        const { user } = this.props;
        const { settingsOpened, cellsSettingsLoading } = this.state;

        if (!settingsOpened) {
            // Nastavenia nie su zobrazene
            return null;
        }

        const userEshopId = toNumber(user.user_eshop_id);

        // Vytiahneme zoznam stlpcov
        const columns = this.prepareCells(this.getColumns());

        let { cellsSettings } = this.state;

        if (_.isEmpty(cellsSettings)) {
            // Zatial nie su nasetovane, nastavime
            cellsSettings = this.getCellsSettings();
        }

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        // Naformatujeme labels buniek
        const cellsLabels = _.reduce(cellsOptions, (result, option, name) => {
            if (_.includes(cellsSettings.disabled, name)) {
                // Disabled nechceme
                return result;
            }

            return { ...result, [name]: option.label };
        }, {});

        return (
            <div className="list__header__settings">
                <div className="list__header__settings__columns">
                    {_.map(columns, (column, key) => {
                        if (column === '') {
                            return null;
                        }

                        return (
                            <div className="list__header__settings__columns__column" key={key}>
                                <div className="list__header__settings__columns__column__name">{column}</div>
                                {_.map(cellsSettings.settings[key], (cellSetting, subKey) => {
                                    const disabled = _.includes(cellsSettings.disabled, cellSetting);

                                    return (
                                        <Select
                                            key={subKey}
                                            options={disabled ? { [cellSetting]: cellsOptions[cellSetting].label } : cellsLabels}
                                            value={cellSetting}
                                            onChange={value => this.onChangeCellSetting(key, subKey, value)}
                                            allowEmpty={false}
                                            disabled={disabled}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <Button
                    onClick={() => this.saveCellsSettings()}
                    loading={cellsSettingsLoading}
                    disabled={_.isEmpty(this.state.cellsSettings) || userEshopId === 0}
                    color="green"
                >{__('Uložiť')}</Button>
            </div>
        );
    }

    /**
     * Rendrovanie filtru.
     *
     * @param {Object} filter
     *
     * @return {JSX.Element|null}
     */
    renderFilter(filter) {
        const { filterOpened, filtered, multiselectIds } = this.state;

        if (!filterOpened || !_.isEmpty(multiselectIds)) {
            // Nie je otvoreny filter alebo je multiselect
            return null;
        }

        return (
            <div className="list__header__filter">
                <div className="list__header__filter__items">
                    {_.map(filter, (item, key) => {
                        const { type, name, options } = item;
                        const value = _.has(filtered, key) ? filtered[key] : item.value;

                        switch (type) {
                            case 'select':
                                if (_.has(item, 'autocomplete') && item.autocomplete) {
                                    const items = _.reduce(options, (result, value, key) => {
                                        return [ ...result, { id: key, name: value } ];
                                    }, []);
                                    let selected = null;

                                    _.each(items, item => {
                                        if (item.id.toString() === value.toString()) {
                                            selected = item;
                                        }
                                    });

                                    return <Input
                                        label={name}
                                        content={<Autocomplete
                                            options={items}
                                            getOptionLabel={option => option.name}
                                            onChange={(event, value) => this.onChangeFilter(key, value !== null ? value.id : '')}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('Začnite písať názov...')}
                                                variant="outlined"
                                            />}
                                            noOptionsText={__('Nenašla sa zhoda')}
                                            clearText={__('Zrušiť')}
                                            value={selected}
                                        />}
                                    />;
                                }

                                let strict = true;

                                if (_.has(item, 'strict') && !item.strict) {
                                    // Vypnuty striktny rezim
                                    strict = false;
                                }

                                return <Select
                                    key={key}
                                    label={name}
                                    options={options}
                                    value={value}
                                    onChange={value => this.onChangeFilter(key, value)}
                                    strict={strict}
                                    multiple={_.has(item, 'multiple')}
                                />;

                            case 'input':
                                return <Input
                                    key={key}
                                    label={name}
                                    value={value}
                                    onChange={value => this.onChangeFilter(key, value)}
                                />;

                            case 'date':
                                return <Input
                                    type="date"
                                    key={key}
                                    label={name}
                                    value={value}
                                    onChange={value => {
                                        if (value === null) {
                                            return;
                                        }

                                        this.onChangeFilter(key, formatDate(value, 'yyyy-mm-dd'));
                                    }}
                                />;

                            default:
                                return null;
                        }
                    })}
                </div>
                <div className="list__header__filter__buttons">
                    <Button
                        onClick={() => this.saveFilter(filtered)}
                        className="list__header__filter__buttons__button"
                        disabled={_.isEmpty(filtered)}
                    >{__('Filtrovať')}</Button>
                    <Button
                        onClick={() => this.resetFilter()}
                        className="list__header__filter__buttons__button"
                        color="red"
                        disabled={_.isEmpty(filtered)}
                    >{__('Resetovať')}</Button>
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme grid.
     *
     * @return {null}
     */
    renderGrid() {
        const { items } = this.props;

        // Vytiahneme zoznam stlpcov
        const columns = this.prepareCells(this.getColumns());

        if (!_.isEmpty(columns)) {
            // Mame stlpce
            return null;
        }

        return (
            <div className={`list__grid ${this.getTableName()}-grid ${this.isMobile() ? 'mobile' : ''}`}>
                {_.map(items.items, (item, key) => {
                    const grid_data = this.renderGridItem(item);

                    if (grid_data === null) {
                        return null;
                    }

                    return <div className="list__grid__item" key={key}>{grid_data}</div>;
                })}
                {_.isEmpty(items.items)
                    ? <div className="list__grid__empty">{_.isEmpty(items.filtered)
                        ? this.getEmptyText()
                        : __('Zadanému filtru nezodpovedajú žiadne položky')}</div>
                    : null}
            </div>
        );
    }

    /**
     * Rendrujeme tabulku.
     *
     * @return {JSX.Element}
     */
    renderTable() {
        const { items, user } = this.props;
        const { multiselectAll } = this.state;

        // Vytiahneme zoznam stlpcov
        const columns = this.prepareCells(this.getColumns());

        if (_.isEmpty(columns)) {
            // Nemame stlpce
            return null;
        }

        // Je aktivny multiselect?
        const hasMultiselect = this.hasMultiselect();

        // Zobrazujeme multiselect
        const showMultiselect = this.showMultiselect();

        const colspan = columns.length + (showMultiselect ? 1 : 9);

        const pagination = <TablePagination
            rowsPerPageOptions={_.remove(user.settings.per_pages, perPage => perPage !== 200)}
            count={items.total}
            rowsPerPage={items.per_page === 200 ? 100 : items.per_page}
            page={items.page - 1}
            getItemAriaLabel={type => type === 'next' ? __('Ďalšia stránka') : __('Predošlá stránka')}
            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
            labelRowsPerPage={this.isMobile() ? __('Počet') : __('Počet záznamov na stránku')}
            onPageChange={(event, page) => this.onChangePage(page + 1)}
            onRowsPerPageChange={event => this.onChangePerPage(parseInt(event.target.value))}
            colSpan={colspan}
        />;

        return (
            <TableContainer className={`list__table ${this.getTableName()}-table ${_.has(user, 'user_eshop_id') && _.includes([38,39,42], user.user_eshop_id) ? 'sellers_owner' : ''} ${this.isMobile() ? 'mobile' : ''}`}>
                <Table size="small">
                    <TableHead>
                        <TableRow>{pagination}</TableRow>
                        <TableRow>
                            {showMultiselect ? <TableCell padding="checkbox">
                                <Checkbox
                                    value={multiselectAll}
                                    onChange={checked => this.onCheckedAll(checked)}
                                    disabled={items.items.length === 0 || !hasMultiselect}
                                />
                            </TableCell> : null}
                            {_.map(columns, (name, key) => <TableCell key={key}>{name}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(items.items, (item, key) => {
                            // Id riadku
                            const id = toNumber(item.id);

                            // Je riadok oznaceni?
                            const checked = this.isChecked(id);

                            // Vytiahneme bunky
                            const cells = this.prepareCells(this.getCells(item));

                            return (
                                <TableRow className={checked ? 'checked' : ''} key={key}>
                                    {showMultiselect ? <TableCell padding="checkbox">
                                        <Checkbox
                                            value={checked}
                                            onChange={checked => this.onChecked(id, checked)}
                                            disabled={!hasMultiselect}
                                        />
                                    </TableCell> : null}
                                    {_.map(cells, (cell, key) => <TableCell key={key}>{cell}</TableCell>)}
                                </TableRow>
                            );
                        })}
                        {items.total === 0 ? <TableRow>
                            <TableCell
                                colSpan={colspan}
                                className="list__empty"
                            >{_.isEmpty(items.filtered)
                                ? this.getEmptyText()
                                : __('Zadanému filtru nezodpovedajú žiadne položky')}</TableCell>
                        </TableRow> : null}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {pagination}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        );
    }

    /**
     * Rendrujeme table buttony.
     *
     * @param {number} itemId
     * @param {Object} buttons
     * @param {Array} ignored
     * @param {bool} showMore
     *
     * @return {JSX.Element[]}
     */
    renderTableButtons(itemId, buttons, ignored = [], showMore = true) {
        if (ignored.length > 0 && showMore) {
            // Mame ignorovane fieldy, zobrazime tlacitko viac
            const { moreOpened } = this.state;

            if (_.includes(moreOpened, itemId)) {
                // Neignorujeme
                ignored = [];
            } else {
                buttons = { ...buttons, more: {
                    name: __('Zobraziť viac'),
                    icon: <MoreIcon />,
                    callback: () => this.showMoreButtons(itemId),
                    options: {},
                } };
            }
        }

        return _.map(buttons, ({ name, icon, callback, options }, key) => {
            if (_.includes(ignored, key)) {
                // Nezobrazujeme
                return null;
            }

            return this.renderTableButton(
                itemId,
                name,
                icon,
                callback,
                options
            );
        });
    }

    /**
     * Rendrujeme grid buttony.
     *
     * @param {number} itemId
     * @param {Object} buttons
     *
     * @return {JSX.Element[]}
     */
    renderGridButtons(itemId, buttons) {
        return _.map(_.values(buttons), ({ name, icon, callback, options }) => this.renderGridButton(
            itemId,
            name,
            icon,
            callback,
            options
        ));
    }

    /**
     * Rendrujeme button v tabulke.
     *
     * @param {number} id
     * @param {string} text
     * @param {JSX.Element} icon
     * @param {function} onClick
     * @param {Object} options
     *
     * @return {JSX.Element}
     */
    renderTableButton(id, text, icon, onClick, options = {}) {
        const { tableButtonLoading } = this.state;

        // Unikatne button id
        const buttonId = this.getLoadingTableButtonId(id, text);
        const loading = tableButtonLoading === buttonId;

        // Je disabled
        const disabled = typeof options.disabled !== 'undefined' && options.disabled;

        if (loading) {
            // Tento button ma loading
            icon = this.renderLoading(20);
        }

        let button = <ButtonBase
            onClick={() => onClick(() => this.loadingTableButton(buttonId), () => this.clearTableButton())}
            disabled={disabled || loading}
            className={`table-button ${disabled ? 'disabled' : ''}`}
            key={buttonId}
        >{icon}</ButtonBase>;

        if (typeof options.link !== 'undefined') {
            // Je zadany link
            button = <Link to={options.link} target={options.target}>{button}</Link>
        }

        if (loading) {
            return button;
        }

        return <Tooltip title={!disabled ? text : ''} key={buttonId}>{button}</Tooltip>;
    }

    /**
     * Rendrujeme button v gride.
     *
     * @param {number} id
     * @param {string} text
     * @param {JSX.Element} icon
     * @param {function} onClick
     * @param {Object} options
     *
     * @return {JSX.Element}
     */
    renderGridButton(id, text, icon, onClick, options = {}) {
        const { gridButtonLoading } = this.state;

        options = { ...{
            link: null,
            target: null,
            disabled: false,
        }, ...options };

        // Unikatne button id
        const buttonId = this.getLoadingGridButtonId(id, text);
        const loading = gridButtonLoading === buttonId;

        if (loading) {
            // Tento button ma loading
            icon = this.renderLoading(20);
        }

        let button = <IconButton
            onClick={() => onClick(() => this.loadingGridButton(buttonId), () => this.clearGridButton())}
            disabled={options.disabled || loading}
            className={`grid-button ${options.disabled ? 'disabled' : ''}`}
            key={buttonId}
        >{icon}</IconButton>;

        if (options.link !== null) {
            // Je zadany link
            button = <Link to={options.link} target={options.target}>{button}</Link>
        }

        if (loading) {
            return button;
        }

        return <Tooltip title={!options.disabled ? text : ''} key={buttonId}>{button}</Tooltip>;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { items } = this.props;

        if (_.isEmpty(items)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        if (!this.isValidPermission()) {
            // Data su nacitane a nemame prava
            return null;
        }

        return (
            <div className="list">
                {this.renderHeader()}
                {this.renderAfterHeader()}
                {this.renderTable()}
                {this.renderGrid()}
                {this.renderSnackbar()}
                {this.renderScreen()}
            </div>
        );
    }
}

export { ListScreen };
